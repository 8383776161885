.Toastify__toast--warning {
  background: #fea569 !important;
}
.Toastify__toast--error {
  background: #f34770 !important;
}

.Toastify__toast--success {
  background-color: #42d697 !important;
}

.Toastify__toast--primary {
  background: #fe696a !important;
}

.Toastify__toast--info {
  background: #69b3fe !important;
}

.Toastify__toast--accent {
  background: #4e54c8 !important;
}